@use '../../styles/yr' as *;

.forecast-page__button-group {
  margin: 0 auto;
  margin-bottom: size(1);

  @include mq-gt($mq-675) {
    margin-bottom: size(2);
  }
}

.forecast-page__graph {
  @include mq-lte($mq-675) {
    margin: 0 size(1);
  }
}

.forecast-page__footer {
  display: flex;
  flex-wrap: wrap;
  gap: size(1);
  padding: size(1) 0;
  @include mq-lte($mq-675) {
    padding: size(1) size(2);
  }
}
